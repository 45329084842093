import React from "react"
import styled, { css } from "styled-components"
import { font, color } from "../../layout/global"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const VideoDetail = ({
  src,
  poster,
  _title,
  id,
  positionCenter,
  titleWidth,
  textWidth,
  text,
  text2,
  videoOpacity,
  positionAbsolute,
  smallHeight,
  link,
  srcOriginal,
  blue,
  isHomePage,
}) => {
  return (
    <Wrapper
      className="container"
      positionCenter={positionCenter}
      titleWidth={titleWidth}
      text={text}
      text2={text2}
      textWidth={textWidth}
      id={id}
      positionAbsolute={positionAbsolute}
      videoOpacity={videoOpacity}
      smallHeight={smallHeight}
      link={link}
      blue={blue}
    >
      {_title && (
        <div className="text-block">
          <h1 dangerouslySetInnerHTML={{ __html: _title }}></h1>
          <span className="p-container">
            {text &&
                <p dangerouslySetInnerHTML={{__html: `${text}<br/>${text2 ? text2 : ""}`}}></p>
            }  
          </span>
        </div>
      )}

      {link && (
        <div className="text-block linked">
          <a rel="noreferrer noopener" href={srcOriginal} target="_blank">
            {text}
          </a>
        </div>
      )}

      <video
        className="video"
        width="100%"
        poster={poster}
        autoPlay
        playsInline
        muted
        loop
      >
        <source src={src} type="video/mp4"></source>
      </video>
    </Wrapper>
  )
}

export default VideoDetail

const Wrapper = styled.section`
  background-color: #000;
  width: 100%;
  min-height: ${props => !props.smallHeight && "100vh"};
  padding: ${props => props.smallHeight && "30px 0"};
  opacity: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.link ? "flex-end" : "center")};

  .video {
    pointer-events: none;
    opacity: ${props => (props.videoOpacity ? "0.5" : "1")};
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: fill;
  }

  .overlay {
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }

  .text-block {
    z-index: 1;
    padding: 0 20px;

    position: ${props => props.positionAbsolute && "absolute"};
    height: ${props => props.positionAbsolute && "100%"};
    display: ${props => props.positionAbsolute && "flex"};
    flex-direction: ${props => props.positionAbsolute && "column"};
    justify-content: ${props => props.positionAbsolute && "space-between"};
    padding: ${props => props.positionAbsolute && "70px 20px"};
  }

  .linked {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20vh;

    a {
      color: ${color.white};
      ${font.robotoMedium};
      font-size: 1.2rem;
    }
  }

  h1 {
    ${font.robotoBold};
    font-size: 3.5rem;
    letter-spacing: 1px;
    line-height: 1.2;
    color: #fff;
    text-align: left;
    width: ${props => props.titleWidth && props.titleWidth};

    & span {
      color: ${props => (props.blue ? "#07c7ed" : color.red)};
    }
  }

  .p-container {
    ${font.roboto400};
    font-size: 1.4rem;
    color: ${color.white};

    p {
      margin: ${props => props.text && "105px 0 0 0"};
      width: ${props => props.textWidth && props.textWidth};
    }
  }

  ${props =>
    props.positionCenter &&
    css`     
      .p-container{
        display:block;
        width: ${props => (props.textWidth ? props.textWidth : "60%")};
        margin: 50px auto;
        text-align:center;

        p{
          margin: 60px 0 0 0
        }
      }

      h1{
        text-align:center;
      }
    }`}

  .dark-background {
    background: rgba(0, 0, 0, 0.2);
  }
`
