import styled from "styled-components"
import { font, color, ease, device } from "../../layout/global" // eslint-disable-line

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: black;
  padding: 20rem 1em 20rem 1em;

  .break {
    flex-basis: 100%;
    height: 0;
  }

  .hidden {
    visibility: hidden;
  }

  .title {
    ${font.robotoBold};
    color: ${color.white};
    font-size: 6rem;
    line-height: 1.2;
    letter-spacing: 0.5px;
    text-align: center;

    span {
      color: ${color.red};
    }

    @media ${device.mobileP} {
      font-size: 4rem;
      line-height: 1;
    }
    @media ${device.desktop} {
      font-size: 6rem;
      line-height: 1.2;
    }
  }

  .subtitle {
    ${font.robotoMedium};
    color: ${color.white};
    text-align: center;

    @media ${device.mobileP} {
      font-size: 1.6rem;
      margin-top: 1em;
    }

    @media ${device.desktop} {
      font-size: 2rem;
    }
  }

  .submit-wrapper {
    margin-top: 3em;
    white-space: nowrap;
  }
`

export default Wrapper
