import React, { useRef } from "react"
import styled from "styled-components"
import { font, color } from "../../layout/global"
import Slider from "react-slick"
import RightArrow from "../../images/Products/right-arrow.svg"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const FeatureLine = ({
  id,
  _title,
  text,
  titleWidth,
  textWidth,
  marginLeft,
  positionCenter,
  image1,
  image2,
  image3,
  whiteBg,
}) => {
  const ref = useRef(null)

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
  }

  return (
    <Wrapper
      titleWidth={titleWidth}
      textWidth={textWidth}
      marginLeft={marginLeft}
      positionCenter={positionCenter}
      whiteBg={whiteBg}
      image1={image1}
      image2={image2}
      image3={image3}
      id={id}
      ref={ref}
    >
      {(_title || text) && (
        <div className="container">
          <h2 dangerouslySetInnerHTML={{ __html: _title }}></h2>
          <p>{text}</p>
        </div>
      )}
      <Slider {...sliderSettings}>
        {image1 && (
          <div className="feat-image">
            <img
              src={image1}
              alt="feature-slider"
              style={{
                objectFit: "contain",
                objectPosition: "center center",
              }}
            />
          </div>
        )}
        {image2 && (
          <div className="feat-image">
            <img
              src={image2}
              alt="feature-slider"
              style={{
                objectFit: "contain",
                objectPosition: "center center",
              }}
            />
          </div>
        )}
        {image3 && (
          <div className="feat-image">
            <img
              src={image3}
              alt="feature-slider"
              style={{
                objectFit: "contain",
                objectPosition: "center center",
              }}
            />
          </div>
        )}
      </Slider>
      <div className="drag">
        <span>
          Arraste para continuar <RightArrow />
        </span>
      </div>
    </Wrapper>
  )
}

export default FeatureLine

const Wrapper = styled.section`
  position: relative;
  background-color: ${color.black};
  width: 100%;
  opacity: 1;
  overflow: hidden;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;

  .container {
    margin-bottom: 50px;
    padding: 0 20px;
  }

  h2 {
    width: 250px;
    margin: ${props => props.positionCenter && "0 auto"};
    text-align: ${props => (props.positionCenter ? "center" : "left")};
    position: relative;
    ${font.robotoBold};
    font-size: 3rem;
    color: ${props => (props.whiteBg ? color.greyDark : color.white)};
    line-height: 1;
    letter-spacing: 1px;
    & span {
      color: ${color.red};
    }
  }
  p {
    margin: 20px 0 0 0;
    font-size: 1.4rem;
    color: ${color.grey};
    ${font.roboto400};
    width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    svg {
      fill: ${color.grey};
      width: 10px;
      margin-left: 10px;
    }
  }

  .slick-track {
    display: flex;
    flex-direction: row;
  }

  .drag {
    position: absolute;
    right: 0;
    bottom: 30px;
    transform: translate(-10%, 0.5%);
    z-index: 1;

    span {
      color: ${color.grey};
      ${font.robotoRegular};
      font-size: 1rem;
      text-align: right;

      svg {
        fill: ${color.grey};
        width: 10px;
        margin-left: 10px;
        transform: translateY(2px);
      }
    }
  }
`
