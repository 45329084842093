import React from "react"
import Wrapper from "./style"
import Submit from "../SubmitButton"
import { Link } from "gatsby"

const Step = ({ id, className, content }) => {
  return (
    <Wrapper id={id} className={`${className} container`}>
      {content && (
        <h1
          className="title"
          dangerouslySetInnerHTML={{ __html: content.in_title }}
        ></h1>
      )}
      <div className="break"></div>
      {content && (
        <p
          className="subtitle"
          dangerouslySetInnerHTML={{ __html: content.in_subtitle }}
        ></p>
      )}
      <div className="break"></div>
      <Link to={content.in_link} className="submit-wrapper">
        <Submit text={content.in_button} hasDarkBackground />
      </Link>
    </Wrapper>
  )
}

export default Step
