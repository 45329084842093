import React, { useEffect, useState, useRef, Fragment } from "react"
import styled from "styled-components"
import { font, ease, color, device } from "../../layout/global"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import LeftArrow from "../../images/Products/left-arrow.svg"
import RightArrow from "../../images/Products/right-arrow.svg"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const ControlPanel = ({ id, defaultImage, carouselContent, _title, className }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isMoving, setIsMoving] = useState(false)
  const wrapperRef = useRef(null),
    containerRef = useRef(null),
    defRef = useRef(null),
    titleRef = useRef(null),
    prevSlide = useRef(null),
    currSlide = useRef(null),
    nextSlide = useRef(null),
    prevArrowRef = useRef(null),
    nextArrowRef = useRef(null)
  const transitionTime = 300 // milliseconds

  useEffect(() => {
    let slides = document.querySelectorAll(`#${id} .slide`)
    slides.forEach((e, i) => {
      e.id = `slide_cp_${i + 1}`
    })
  }, [id])

  useEffect(() => {
    let currImage, currDesc, prevImage, prevDesc, nextImage, nextDesc

    currSlide.current = document.querySelector(`#${id} #slide_cp_${currentSlide + 1}`)
    currImage = currSlide.current.querySelector(".cp-image-container")
    currDesc = currSlide.current.querySelector(".cp-description")
    currImage.style.opacity = 1
    currDesc.style.opacity = 1

    if (currentSlide === 0) {
      prevSlide.current = null
      nextSlide.current = currSlide.current.nextElementSibling
      prevArrowRef.current.style.opacity = 0
      prevArrowRef.current.style.pointerEvents = "none"
      nextArrowRef.current.style.opacity = 1
      nextArrowRef.current.style.pointerEvents = "all"
      nextArrowRef.current.style.animationPlayState = "running"
      nextSlide.current && (nextImage = nextSlide.current.querySelector(".cp-image-container"))
      nextSlide.current && (nextDesc = nextSlide.current.querySelector(".cp-description"))
      nextImage.style.opacity = 0
      nextDesc.style.opacity = 0
      setTimeout(() => {
        setIsMoving(false)
      }, transitionTime)
    } else if (currentSlide === carouselContent.length - 1) {
      prevSlide.current = currSlide.current.previousElementSibling
      nextSlide.current = null
      prevArrowRef.current.style.opacity = 1
      prevArrowRef.current.style.pointerEvents = "all"
      nextArrowRef.current.style.opacity = 0
      nextArrowRef.current.style.pointerEvents = "none"
      prevArrowRef.current.style.animationPlayState = "running"
      prevSlide.current && (prevImage = prevSlide.current.querySelector(".cp-image-container"))
      prevSlide.current && (prevDesc = prevSlide.current.querySelector(".cp-description"))
      prevImage.style.opacity = 0
      prevDesc.style.opacity = 0
      setTimeout(() => {
        setIsMoving(false)
      }, transitionTime)
    } else {
      prevSlide.current = currSlide.current.previousElementSibling
      nextSlide.current = currSlide.current.nextElementSibling
      prevArrowRef.current.style.opacity = 1
      prevArrowRef.current.style.pointerEvents = "all"
      nextArrowRef.current.style.opacity = 1
      nextArrowRef.current.style.pointerEvents = "all"
      prevArrowRef.current.style.animationPlayState = "paused"
      nextArrowRef.current.style.animationPlayState = "paused"
      prevSlide.current && (prevImage = prevSlide.current.querySelector(".cp-image-container"))
      prevSlide.current && (prevDesc = prevSlide.current.querySelector(".cp-description"))
      nextSlide.current && (nextImage = nextSlide.current.querySelector(".cp-image-container"))
      nextSlide.current && (nextDesc = nextSlide.current.querySelector(".cp-description"))
      prevImage.style.opacity = 0
      prevDesc.style.opacity = 0
      nextImage.style.opacity = 0
      nextDesc.style.opacity = 0
      setTimeout(() => {
        setIsMoving(false)
      }, transitionTime)
    }
  }, [currentSlide, carouselContent.length]) // eslint-disable-line

//   useEffect(() => {
//     gsap
//       .timeline({
//         id: "control-panel",
//         scrollTrigger: {
//           trigger: wrapperRef.current,
//           start: "5% center",
//           end: "95% center",
//           toggleActions: "play complete none",
//           onLeave: () =>
//             gsap.to(containerRef.current, {
//               opacity: 0,
//               duration: 1,
//             }),
//           onEnterBack: () =>
//             gsap.to(containerRef.current, {
//               opacity: 1,
//               duration: 1,
//             }),
//         },
//       })
//       .to(containerRef.current, {
//         opacity: 1,
//         duration: 1,
//       })
//   }, [])

  const Prev = () => {
    if (!isMoving && currentSlide !== 0) {
      setIsMoving(true)
      setCurrentSlide(currentSlide - 1)
    }
  }
  const Next = () => {
    if (!isMoving && currentSlide !== carouselContent.length - 1) {
      setIsMoving(true)
      setCurrentSlide(currentSlide + 1)
    }
  }
  const updateCurrentSlide = index => {
    currentSlide !== index && setCurrentSlide(index)
  }

  return (
    <Wrapper transitionTime={transitionTime} id={id} ref={wrapperRef}>
      <div className="dff" ref={containerRef}>
        {defaultImage && (
          <div ref={defRef} className="cp-default-image-container">
            <img
              src={defaultImage}
              alt="panel"
              style={{
                objectFit: "contain",
                objectPosition: "center center",
              }}
            />
          </div>
        )}
        <h2 className="cp-title" ref={titleRef}>
          {_title.title1}
          <br />
          {_title.title2}
        </h2>
        <Container
          showArrows={false}
          showIndicators={false}
          showThumbs={false}
          showStatus={false}
          infiniteLoop={false}
          autoPlay={false}
          transitionTime={0}
          transition={transitionTime}
          interval={0}
          className={className}
          selectedItem={currentSlide}
          onChange={updateCurrentSlide}
          swipeable={true}
        >
          {carouselContent.map((i, index) => (
            <Fragment key={index}>
              <p className="cp-description">{i.text}</p>
              <div className="cp-image-container">
                {i.image && (
                  <img
                    src={i.image}
                    alt="panel"
                    style={{
                      objectFit: "contain",
                      objectPosition: "center center",
                    }}
                  />
                )}
              </div>
            </Fragment>
          ))}
        </Container>
        <div className="pagination">
          <button id="left-arrow" onClick={Prev} ref={prevArrowRef} aria-label="Previous Item">
            <LeftArrow />
          </button>
          <div>
            <span id="curr-page">0{currentSlide + 1}</span> / <span id="last-page">0{carouselContent.length}</span>
          </div>
          <button id="right-arrow" onClick={Next} ref={nextArrowRef} aria-label="Next Item">
            <RightArrow />
          </button>
        </div>
      </div>
    </Wrapper>
  )
}

export default ControlPanel

const Wrapper = styled.section`
  position: relative;

  .dff {
    opacity: 1;
  }

  .cp-title {
    position: absolute;
    top: 15%;
    left: 7.5%;
    ${font.robotoBold};
    font-size: 8rem;
    color: ${color.grey};
    line-height: 1;
    z-index: 1;
  }
  .cp-default-image-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .pagination {
    position: absolute;
    bottom: 20%;
    right: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    ${font.robotoRegular};
    color: ${color.white};
    font-size: 3rem;
    letter-spacing: -1px;
  }
  #curr-page {
    font-size: 5rem;
  }
  #last-page {
    font-size: 3rem;
  }
  button {
    border: 0;
    background: none;
    margin-top: 5px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    outline: 0;
    cursor: pointer;

    &:hover svg {
      fill: ${color.red};
    }
  }

  button svg {
    height: 100%;
    fill: white;
    transition: all 0.15s ${ease.out};
  }
  #left-arrow {
    margin-right: 10px;
    animation: arrowMoveLeft 1s infinite;
    animation-timing-function: ease-in-out;

    &:hover {
      animation-play-state: paused;
    }
  }
  #right-arrow {
    margin-left: 10px;
    animation: arrowMoveRight 1s infinite;
    animation-timing-function: ease-in-out;

    &:hover {
      animation-play-state: paused;
    }
  }

  @keyframes arrowMoveLeft {
    0% {
      transform: translateX(0px);
    }
    50% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(0px);
    }
  }
  @keyframes arrowMoveRight {
    0% {
      transform: translateX(0px);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  @media ${device.tabletP} and (max-width: 1920px) {
    .cp-title {
      font-size: calc(3.5rem + 45 * (100vw - 768px) / (1920 - 768));
    }
    .pagination {
      height: calc(15px + 10 * (100vw - 768px) / (1920 - 768));
      font-size: calc(1.5rem + 15 * (100vw - 768px) / (1920 - 768));
      letter-spacing: calc(0 - 1 * (100vw - 768px) / (1920 - 768));
    }
    #curr-page {
      font-size: calc(2rem + 30 * (100vw - 768px) / (1920 - 768));
    }
    #last-page {
      font-size: calc(1.5rem + 15 * (100vw - 768px) / (1920 - 768));
    }
    #left-arrow {
      margin-right: calc(0px + 10 * (100vw - 768px) / (1920 - 768));
    }
    #right-arrow {
      margin-left: calc(0px + 10 * (100vw - 768px) / (1920 - 768));
    }
  }
`

const Container = styled(Carousel)`
  .carousel .slide {
    transition: all 0.15s ${ease.out};
    background: none;
  }
  .carousel.carousel-slider .control-arrow:hover {
    background: none;
  }
  .container,
  .image > .gatsby-image-wrapper {
    height: 100%;
  }
  .container {
    position: relative;
  }
  .cp-image-container {
    transition: ${props => `all ${props.transition}ms ${ease.out}`};
  }
  .cp-description {
    position: absolute;
    top: 45%;
    left: 7.5%;
    width: 350px;
    ${font.roboto400};
    font-size: 2.2rem;
    color: ${color.white};
    z-index: 1;
    transform: translateY(20px);
    transition: ${props => `all ${props.transition}ms ${ease.out}`};
  }

  @media ${device.tabletP} and (max-width: 1920px) {
    .cp-description {
      font-size: calc(1.4rem + 8 * (100vw - 768px) / (1920 - 768));
      width: calc(200px + 150 * (100vw - 768px) / (1920 - 768));
      text-align: left;
    }
  }
`
